@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

:root {
    --c1: #E7EBC5;
    --c2: #A78682;
    --c3: #6F5060;
    --c4: #51344D;
    --c5: #989788;
    --acc: #005f85;
}

body {
    margin: 0;
    background-color: var(--c1);
    font-family: 'Roboto', sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    min-height: calc(100vh - 6em);
    margin-top: 3vh;
    flex-direction: column;
    display: flex;
    width: 60%;
}

nav {
    margin: 0;
    width: 100%;
    min-height: 8vh;
    background-color: var(--c4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-left, .nav-center, .nav-right {
    flex: 1;
    display: flex;
    align-items: center;
}

.nav-left {
    justify-content: flex-start;
}

.nav-left img {
    height: 6.5vh;
    margin-left: 1vw;
}

.nav-center {
    justify-content: center;
}

.nav-center h1 {
    color: var(--c1);
    font-size: 2.8em;
    margin: 0;
}

.nav-right {
    justify-content: flex-end;
}

#not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--c1);
    color: var(--c5);
}

menu {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--c1);
}

menu ul {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    list-style-type: none;
    padding: 0;
}

menu li {
    margin: 10px 0;
}

menu a {
    text-decoration: none;
    color: var(--c1);
    padding: 10px 20px;
    background-color: var(--c3);
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

menu a:hover {
    background-color: var(--acc);
    color: var(--c5);
}

form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

ul {
    width: 100%;
}
